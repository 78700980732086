import React from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import { Carousel } from 'react-bootstrap'
import { Video } from '../../components/main-render/internal-pages/video'
import { LandingForm } from './LandingForm/landingForm'
import LandingFooter from './LandingFooter/landingFooter'
import ThemeProvider from '../../context/themeContext'
import Seo from '../../components/seo'
import '../../css/_landing.scss'

const LandingPage = (props) => {
  const { data } = props
  const { strapiLandingPages: landingPage } = data

  const numbers = landingPage.numbers.number.map((num, i) => {
    return (
      <div key={`num-${i}`} className="numbersItem">
        <h1 className="font-weight-extra-bold">{num.quantity}</h1>
        <p>{num.description}</p>
      </div>
    )
  })

  return (
    <ThemeProvider>
      <>
        <Seo
          title={`${landingPage.seo}`}
        />
        <div className="landing">
          <div className="landing__navbar container">
            <img src={landingPage.logo.image.url} alt={landingPage.title} />
          </div>
          <div
            className="landing__banner"
            style={{
              backgroundImage: `url(${landingPage.bannerImage.url}), linear-gradient(to right, rgba(24, 44, 73, 1) 0%, rgba(24, 44, 73, 0) 40%)`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundBlendMode: 'overlay'
            }}
          >
            <div className="landing__banner_title container">
              <span className="ucc-text-blue">{landingPage.subtitle}</span>
              <h1 className="text-uppercase text-white font-weight-bold">
                {landingPage.title}
              </h1>
            </div>
          </div>

          <div className="landing__textForm container">
            <div className="landing__textForm_text">
              {' '}
              <ReactMarkdown
                className="markdown-format"
                source={landingPage.textContent}
              />
            </div>
            <div className="landing__textForm_form">
              <LandingForm
                formTitle={landingPage.formTitle}
                color={landingPage.color}
                interestType={landingPage.interest_type}
                interestReceiver={landingPage.interest_receiver}
                redirectFormPage={landingPage.redirectFormPage}
              />
            </div>
          </div>

          <div className='bg-white py-2'>
            <Carousel
              className='landing__carousel'
              slide={landingPage.profileList.profile.length > 1}
              indicators={landingPage.profileList.profile.length > 1}
              controls={landingPage.profileList.profile.length > 1}
            >
              {landingPage.profileList.profile.map((profile, i) => {
                return (
                  <Carousel.Item
                    interval={10000}
                    key={`idProfile-${i}`}
                    className="h-100"
                  >
                    <div className="landing__profile container bg-white">
                      <div className="landing__profile_photo">
                        <img
                          src={profile.image.url}
                          alt={profile.title}
                        />
                      </div>
                      <div className="landing__profile_text">
                        <h4>{profile.description}</h4>
                        <p>{profile.title}</p>
                      </div>
                    </div>
                  </Carousel.Item>
                )
              })}
            </Carousel>
          </div>

          <div className="landing__numbers">
            <div className="container">{numbers}</div>
          </div>

          <div className="landing__video">
            <Video
              videoComponent={landingPage.video}
              key={`videoId-${landingPage.id}`}
            />
          </div>
          <LandingFooter whatsApp={landingPage.whatsAppNumber} />
        </div>
      </>
    </ThemeProvider>
  )
}

export default LandingPage

export const landingPageQuery = graphql`
  query($landingPageId: Int) {
    strapiLandingPages(strapiId: { eq: $landingPageId }) {
      strapiId
      id
      title
      subtitle
      slug
      seo
      formTitle
      color
      whatsAppNumber
      textContent
      logo {
        visible
        url
        title
        image {
          url
        }
      }
      bannerImage {
        url
      }
      profileList {
        profile {
          title
          id
          description
          image {
            url
          }
        }
      }
      numbers {
        visible
        number {
          quantity
          id
          description
        }
      }
      video {
        visible
        videoId
        title
        description
        id
      }
      interest_type {
        type
        option
      }
      interest_receiver {
        option
        receiver
      }
      redirectFormPage {
        name
        slug
      }
    }
  }
`
