import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import '../../../components/font-awesome'
import './landingFooter.scss'

const LandingFooter = ({
  whatsApp = null
}) => {
  
  const logosList = [
    {
      pageUrl: "https://www.ucc.edu.ar/",
      imageUrl: "https://ucc-uploads-qa.s3.sa-east-1.amazonaws.com/footer1_b3fce791dc.png" 
    },
    {
      pageUrl: "https://www.ausjal.org/",
      imageUrl: "https://ucc-uploads-qa.s3.sa-east-1.amazonaws.com/ausjal_logo_ac6fb8c1c4.png" 
    },
    {
      pageUrl: null,
      imageUrl: "https://ucc-uploads-qa.s3.sa-east-1.amazonaws.com/iaju_web_074d534722.png" 
    },
    {
      pageUrl: "https://jesuitasaru.org/",
      imageUrl: "https://ucc-uploads-qa.s3.sa-east-1.amazonaws.com/jesuitas_66729d11b0.png" 
    }
  ]

  return (
    <footer className="py-5 footer-home text-white">
      {/* Whats App Button */}
      {whatsApp &&
        <div className="btn-whatsapp" key={`link-whatsapp`}>
          <a href={`https://api.whatsapp.com/send/?phone=${whatsApp}&text&type=phone_number&app_absent=0`} target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faWhatsapp} className="icon-whatsapp"/>
          </a>
        </div>
      }

      <div className="container">
        <div className="row">
          <div className="col-12 col-md-5 col-lg-3 d-flex mb-4 justify-content-center">
            <div className="d-flex flex-column align-items-center ">
              <div className="d-flex mb-2 pt-2">
                <a
                  href="https://es-la.facebook.com/UniversidadCatolicaCordoba/"
                  target="_blank"
                  className="btn btn-social m-1 btn-social-icon btn-facebook"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={['fab', 'facebook-f']} size="sm" />
                </a>
                <a
                  href="https://www.youtube.com/uccoficial"
                  target="_blank"
                  className="btn btn-social m-1 btn-social-icon btn-youtube"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={['fab', 'youtube']} size="sm" />
                </a>
                <a
                  href="https://ar.linkedin.com/school/universidad-cat-lica-de-c-rdoba/"
                  target="_blank"
                  className="btn btn-social m-1 btn-social-icon btn-linkedin"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={['fab', 'linkedin-in']} size="sm" />
                </a>
                <a
                  href="https://www.instagram.com/uccoficial/?hl=es-la"
                  target="_blank"
                  className="btn btn-social m-1 btn-social-icon btn-instagram"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={['fab', 'instagram']} size="sm" />
                </a>
                <a
                  href="https://twitter.com/UCCoficial"
                  target="_blank"
                  className="btn btn-social m-1 btn-social-icon btn-twitter"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={['fab', 'x-twitter']} size="sm" />
                </a>
                <a
                  href="https://www.tiktok.com/@uccoficial"
                  target="_blank"
                  className="btn btn-social m-1 btn-social-icon btn-tiktok"
                  rel="noreferrer"
                >
                   <FontAwesomeIcon icon={['fab', 'tiktok']} size="sm" />
                </a>
              </div>
              <h5 className="text-white font-weight-medium text-center">
                ucc.edu.ar
              </h5>
            </div>
          </div>
          <div className="col-12 col-md-7 col-lg-9 mb-4">
            <div className="d-flex flex-wrap align-items-center logos-footer">
              {logosList.map((logo, i) => {
                return (
                  <a
                    href={logo.pageUrl}
                    target="about_blank"
                    key={`key-logo-footer-${i}`}
                  >
                    <img
                      src={logo.imageUrl}
                      className="img-logo-footer my-2 mx-1"
                      alt="logoFooter01"
                    />
                  </a>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default LandingFooter
