export default async function countryData(countryName) {
  return new Promise(async (resolve, reject) => {

    try {

      // Abreviatura del pais segun ISO 3166-1
      const isoCountry = 'AR';

      // Estado para almacenar el código de país
      let countryCode = '+54'; 
      
      const encodedCountryName = encodeURIComponent(countryName || "Argentina");

      // Obteniendo informacion de la ubicacion
      fetch(`https://restcountries.com/v3.1/name/${encodedCountryName}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Error al obtener la información del país');
          }
          return response.json();
        })

      .then(data => {
        // Toda la informacion de un Pais
        const countryData = data[0];
      
        if (countryData) {
          // Estraigo abreviatura del pais segun ISO 3166-1 alpha2
          const isoCountry = countryData.altSpellings[0];
          
          // Extraigo el código de teléfono del país
          const firstNumber = countryData.idd.root;
          let secondaryNumber = ""

          if (firstNumber === "+1" || firstNumber === "+7") {
            countryCode = firstNumber;
          } else {
            secondaryNumber = countryData.idd.suffixes[0];
            countryCode = `${firstNumber}${secondaryNumber}`;
          }

          const countryDataResult = {
            isoCountry: isoCountry,
            countryCode: countryCode
          }

          resolve(countryDataResult);
        } else {
          // Valores predeterminados si no encuentro el pais
          const countryDataResult = {
            isoCountry: isoCountry,
            countryCode: countryCode
          }
          resolve(countryDataResult);


        }
      })
      .catch(error => {
        console.error('Error al obtener la información del país:', error);
        
        // Valores predeterminados en caso de error
        const countryDataResult = {
          isoCountry: isoCountry,
          countryCode: countryCode
        }
        resolve(countryDataResult);
      });
 
    }
    catch (error) {
      reject(error);
    }
  })
}