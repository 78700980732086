
export default async function geolocation() {
  return new Promise(async (resolve, reject) => {
        
    try {
      // Verificar si la geolocalización está habilitada en el navegador
      if ('geolocation' in navigator) {
    
        // Obtener la ubicación del usuario
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
              
            // Servicio de geocodificación inversa para obtener el país - API Nominatim
            const nominatimUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=10`;

            try {
              const response = await fetch(nominatimUrl);
              const data = await response.json();

              // Extraigo el país de los datos de la geocodificación inversa
              const country = data.address.country;

              if (country) {
                resolve(country);
              } else {
                resolve('Argentina');
              }

            } catch (error) {
              console.error('Error al obtener la ubicación:', error);
              resolve('Argentina');
            }
          },
          (error) => {
            console.error('Error al obtener la ubicación:', error);
            resolve('Argentina'); 
          }
        );
    
      } else {
        // Geolocalización no está habilitada, selecciono Pais por defecto
        resolve('Argentina');
      }
      
    } catch (error) {
      reject(error);
    }
  })
}